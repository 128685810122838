.ri-grid{
	margin: 30px auto 30px;
	position: relative;
	height: auto;
}

.ri-grid ul {
	list-style: none;
	display: block;
	width: 100%;
	margin: 0;
	padding: 0;
}

/* Clear floats by Nicolas Gallagher: http://nicolasgallagher.com/micro-clearfix-hack/ */

.ri-grid ul:before,
.ri-grid ul:after{
	content: '';
    display: table;
}

.ri-grid ul:after {
    clear: both;
}

.ri-grid ul {
    zoom: 1; /* For IE 6/7 (trigger hasLayout) */
} 

.ri-grid ul li {
	-webkit-perspective: 400px;
	-moz-perspective: 400px;
	-o-perspective: 400px;
	-ms-perspective: 400px;
	perspective: 400px;	
	margin: 0;
	padding: 0;
	float: left;
	position: relative;
	display: block;
	overflow: hidden;
	background: #000;
}

.ri-grid ul li a{
	display: block;
	outline: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-o-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: #333;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

/* Grid wrapper sizes */
.ri-grid-size-1{
	width: 55%;
}
.ri-grid-size-2{
	width: 100%;
}
.ri-grid-size-3{
	width: 100%;
	margin-top: 0px;
}

/* Shadow style */
.ri-shadow:after,
.ri-shadow:before{
	content: "";
	position: absolute;
	z-index: -2;
	bottom: 15px;
	left: 10px;
	width: 50%;
	height: 20%;
	max-width: 300px;
	max-height: 100px;
	box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
	-webkit-transform: rotate(-3deg);
	-moz-transform: rotate(-3deg);
	-ms-transform: rotate(-3deg);
	-o-transform: rotate(-3deg);
	transform: rotate(-3deg);
}
.ri-shadow:after{
	right: 10px;
	left: auto;
	-webkit-transform: rotate(3deg);
	-moz-transform: rotate(3deg);
	-ms-transform: rotate(3deg);
	-o-transform: rotate(3deg);
	transform: rotate(3deg);
}

.ri-grid-loading:after,
.ri-grid-loading:before{
	display: none;
}

.ri-loading-image{
	display: none;
}

.ri-grid-loading .ri-loading-image{
	position: relative;
	width: 30px;
	height: 30px;
	left: 50%;
	margin: 100px 0 0 -15px;
	display: block;
}