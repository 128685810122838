.map {
    height: 500px;
    width: 100%;
}
.pi-extend-map-wrapper {
    position: relative;
}
.pi-extend-map-wrapper.loading-image:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 9999999;
  background-color: #1abc9c;
  width: 30px;
  height: 30px;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  -webkit-animation: maploading 1s linear infinite;
  -moz-animation: maploading 1s linear infinite;
  animation: maploading 1s linear infinite;
}
@-webkit-keyframes maploading {
    0% {
        -webkit-transform: scale(.2);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(3);
        opacity: 0;
    }
}
@-moz-keyframes maploading {
    0% {
        -moz-transform: scale(.2);
        opacity: 1;
    }
    100% {
        -moz-transform: scale(3);
        opacity: 0;
    }
}
@keyframes maploading {
    0% {
        transform: scale(.2);
        opacity: 1;
    }
    100% {
        transform: scale(3);
        opacity: 0;
    }
}
.pi-nav-map {
    position: absolute;
    width: 220px;
    top: 10px;
    left: 10px;
    bottom: 10px;
    background-color: #fff;
    z-index: 999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.pi-nav-map.pi-nav-map-hide {
    bottom: auto;
    height: 52px;
}
.pi-nav-map h2 {
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin: 0;
    padding: 16px 15px;
    border-bottom: 1px solid #e7e7e7;
    cursor: pointer;
    -webkit-transition: all .3s ease;
       -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
         -o-transition: all .3s ease;
            transition: all .3s ease;
}
.pi-nav-map h2 .fa {
    font-size: 18px;
    float: right;
}
.pi-nav-map h2:hover {
    color: #1abc9c;
}
.pi-contents-map {
    height: 100%;
    width: 100%;
}
.pi-only-content {
    width: 100%;
}
.pi-map-group {
    width: 100%;
    border: 0;
    color: #646464;
}
.pi-map-list-location-mobile {
    width: calc(100% - 120px);
    line-height: 52px;
    height: 52px;
}
.pi-map-list-select-location {
    top: 40px;
    right: 10px;
    background-color: #f1f1f1;
    border-color: #999;
    color: #444;
    font-weight: 600;
}
.pi-map-list-select-styles {
    top: 100px;
    width: 130px;
    right: 20px;
    background: #000;
    padding: 5px;
    display: none;
}
.pi-list-item-style {
    width: 30px;
    height: 30px;
    border: 1px solid #fff;
    display: inline-block;
    margin: 5px;
    cursor: pointer;
}
.pi-map-list-location {
    position: static;
    list-style: none;
    padding: 0 15px;
    font-size: 13px;
    color: #646464;
    width: 100%;
}
.pi-map-list-location .name-group {
    display: block;
    color: #373737;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 10px 15px;
    border-bottom: 1px solid #eee;
    margin-left: -15px;
    margin-right: -15px;
}
.pi-map-list-location li {
    cursor: pointer;
}
.pi-map-list-location .list-location-group {
    display: none;
    padding: 8px 0;
}
.pi-map-list-location .items-location-group li {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; 
    padding-top: 6px;
    padding-bottom: 6px;
}
.pi-map-list-location ul {
    list-style: none;
    padding-left: 0;
}
.pi-map-list-location li .fa {
    margin-right: 10px;
}

.pi-map-list-location .items-location-group.active + .items-location-group .name-group {
    border-top: 1px solid #eee;
}
.pi-map-list-location .items-location-group.active .name-group {
    background-color: #fafafa;
}
.pi-map-list-location .items-location-group.active .name-group,
.pi-map-list-location .name-group:hover,
.pi-map-list-location .list-location-group li:hover,
.pi-map-list-location .list-location-group li.active {
    color: #1abc9c;
}
.pi-map-list-control {
    height: 100%;
}

@media screen and (max-width: 767px) {
    .pi-nav-map {
        top: auto;
        left: 0;
        bottom: 0;
        width: 100%;
    }
    .pi-nav-map h2 {
        display: none;
    }
    .pi-nav-map:after {
        content: "\F0D7";
        position: absolute;
        display: inline-block;
        font-family: 'FontAwesome';
        width: 52px; height: 52px;
        text-align: center;
        line-height: 52px;
        background-color: #fff;
        color: #333;
        font-size: 16px;
        pointer-events: none;
        top: 0; right: 120px;
        z-index: 999;
    }
}